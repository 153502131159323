import React from "react"
import { Layout } from "../components/layout"
import starImg from "../images/star.svg"
import checkImg from "../images/check.svg"
import logo from "../images/logo.svg"
import alexImg from "../images/Alexander.png"
import valentinImg from "../images/Valentin.png"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allContentfulOnlyFansShort {
      edges {
        node {
          heading
          getYourSeatButtonText
          yourHosts
          registerNow
          vimeoVideoUrl
          subHeading {
            subHeading
          }
          seo {
            data {
              description
              title
            }
          }
          mainFormFeatures {
            title
            description
          }
          agencyFounderText
          legal {
            data {
              privacy {
                title
                url
              }
              terms {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

const Item = ({ item }) => (
  <div className="flex gap-x-4">
    <div>
      <div className="w-8 h-8">
        <img className="w-full h-full" src={checkImg} alt="Checkmark" />
      </div>
    </div>
    <div>
      <h3 className="font-bold text-white font-title">{item.title}</h3>
      <p className="mt-4 text-sm text-white opacity-70 font-body">
        {item.description}
      </p>
    </div>
  </div>
)

export default function IndexPage({ data }) {
  const dataNode = data.allContentfulOnlyFansShort.edges[0].node

  return (
    <Layout data={dataNode}>
      <div className="flex justify-center p-4 md:justify-start md:p-8">
        <div className="w-24 text-white md:w-auto">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="flex justify-center mt-2 md:mt-12">
        <div className="px-4 py-1 uppercase bg-orange1">
          <p className="text-xs italic font-bold text-black cursor-default font-title md:text-base">
            Live Webinar
          </p>
        </div>
      </div>
      <div className="w-full px-4 mx-auto mt-6 md:w-920 md:px-0 md:mt-12">
        <div>
          <h1
            dangerouslySetInnerHTML={{ __html: dataNode.heading }}
            className="text-2xl italic font-bold text-center text-white uppercase md:text-4xl font-title"
          ></h1>
          <p
            dangerouslySetInnerHTML={{ __html: dataNode.subHeading.subHeading }}
            className="mt-6 text-sm text-center text-gray-400 font-body md:mt-10 md:text-base"
          ></p>
        </div>
        <div className="flex flex-col justify-center md:flex-row md:gap-x-6 md:mt-14">
          <div className="md:w-1/2">
            <div className="w-full overflow-hidden rounded-2xl">
              <iframe
                className="w-full"
                src={dataNode.vimeoVideoUrl}
                width="640"
                height="260"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="block text-center md:hidden">
              <button
                type="button"
                data-webinarhash="mg47wtg"
                className="w-full text-gray-800 cursor-pointer wj-embed-button bg-orange1 font-title"
                style={{
                  border: "2px solid rgba(0, 0, 0, 0)",
                  fontSize: "22px",
                  padding: "16px 0px",
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 8px 2px",
                  borderRadius: "4px",
                  whiteSpace: "normal",
                  fontWeight: 700,
                  verticalAlign: "baseline",
                  lineHeight: 1.3,
                  cursor: "pointer",
                  wordBreak: "break-word",
                  margin: "auto",
                }}
              >
                <span
                  className="inline-block text-3xl align-middle"
                  style={{ lineHeight: 0 }}
                >
                  {">"}
                </span>
                <span className="inline-block italic">
                  {dataNode.getYourSeatButtonText}
                </span>
                <span className="inline-block text-3xl align-middle">
                  {"<"}
                </span>
              </button>
            </div>
            <div className="space-y-4 md:mt-12 mt-14">
              {dataNode.mainFormFeatures.map((item) => (
                <Item item={item} />
              ))}
            </div>
          </div>
          <div className="hidden md:w-1/2 md:block">
            <div className="w-full overflow-hidden bg-white rounded-2xl">
              <p className="mt-8 text-sm text-center font-title">
                Live Webinar
              </p>
              <h3 className="mt-2 text-xl italic font-bold text-center font-title">
                {dataNode.registerNow}
              </h3>
              <div
                className="wj-embed-wrapper"
                data-webinar-hash="mg47wtg"
              ></div>
            </div>
          </div>
        </div>
        <div className="mt-20 md:mt-24">
          <h2 className="text-2xl italic font-bold text-center text-white md:text-4xl font-title">
            {dataNode.yourHosts}
          </h2>
          <div className="flex flex-col justify-center mt-10 md:flex-row md:gap-x-4 gap-y-4">
            <div className="flex items-center justify-start w-full px-2 py-8 bg-back2 bg-opacity-10 md:px-8 md:py-12 md:text-center rounded-2xl md:flex-col md:w-1/3 gap-x-4 md:gap-x-0">
              <div className="w-14 h-14">
                <img src={valentinImg} alt="" />
              </div>
              <div>
                <p className="mt-2 font-bold text-white font-title">
                  Valentin Klinger
                </p>
                <p className="mt-1 text-xs text-white opacity-30 font-title">
                  {dataNode.agencyFounderText}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-start w-full px-2 py-8 bg-back2 bg-opacity-10 md:px-8 md:py-12 md:text-center rounded-2xl md:flex-col md:w-1/3 gap-x-4 md:gap-x-0">
              <div className="w-14 h-14">
                <img src={alexImg} alt="" />
              </div>
              <div>
                <p className="mt-2 font-bold text-white font-title">
                  Alexander Rosdow
                </p>
                <p className="mt-1 text-xs text-white opacity-30 font-title">
                  {dataNode.agencyFounderText}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-start w-full px-2 py-8 bg-back2 bg-opacity-10 md:px-8 md:py-12 md:text-center rounded-2xl md:flex-col md:w-1/3 gap-x-4 md:gap-x-0">
              <div className="w-14 h-14">
                <img src={starImg} alt="" />
              </div>
              <div>
                <p className="mt-2 font-bold text-white font-title">
                  Special Guest
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
